export default defineNuxtRouteMiddleware(async (from, to) => {
  // Fetch the user info API on every route change to ensure the user has access to the data they view.
  const appStore = useAppStore()
  const emitter = useEmitter()
  if (
    appStore.isUnderMaintenance &&
    from.path !== '/maintenance/' &&
    from.path !== '/maintenance'
  ) {
    // Redirect the user to the maintenance page if the site is under maintenance.
    return navigateTo('/maintenance/')
  } else if (
    !appStore.isUnderMaintenance &&
    (from.path === '/maintenance/' || from.path === '/maintenance')
  ) {
    // If the site is not under maintenance, redirect to the home page if the user requests the maintenance page.
    return navigateTo('/')
  }

  if (from.path === '/schema/' || from.path === '/schema') {
    return
  }

  if (
    from.path === '/sample-dashboards/' ||
    from.path === '/sample-dashboards' ||
    from.path === '/viz/' ||
    from.path === '/viz'
  ) {
    await navigateTo('/', {
      external: true,
    })
    emitter.emit('setActiveRoutePath')
  }

  // Add all future redirects here
  const redirects = [
    {
      from: /^\/solutions/i,
      to: '/sample-dashboards',
    },
    {
      from: /^\/products/i,
      to: '/data',
    },
    {
      from: /^\/help/i,
      to: '/guides',
    },
  ]
  // Custom redirects with hash
  const redirectsWithHash: Record<string, string> = {
    '#CMS.CMS_MEDICARE_CLOSED_CLAIMS':
      '/schema/v2/#CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    '#CMS.ALL_MEDICARE_COMPLETE_CLAIMS_READYDATA':
      '/schema/v2/#CMS.ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
  }

  const hashRedirect = redirectsWithHash[from.hash]
  if (hashRedirect) {
    return navigateTo(hashRedirect)
  }

  redirects.forEach((redirectURL) => {
    if (redirectURL.from.test(from.path)) {
      return navigateTo(from.path.replace(redirectURL.from, redirectURL.to))
    }
  })

  // For better GA4-Google Analytics
  if (from.path.slice(-1) !== '/') {
    return navigateTo(from.path + '/', { redirectCode: 301, replace: true })
  }

  // Fetch user information on every route change to perform authorization checks
  if (
    from.path.slice(-1) === '/' &&
    from.path !== to.path &&
    !from.path.includes('maintenance')
  ) {
    clearError()
    appStore.fetchUser('all', true)
  }
})
